export const columnasLibros = [
  {
    label: "idLibro",
    field: "idLibro",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "title",
    field: "title",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "subject",
    field: "subject",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "subject2",
    field: "subject2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "description",
    field: "description",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "descriptionENG",
    field: "descriptionENG",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "author",
    field: "author",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "authorAlt",
    field: "authorAlt",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "publicationPlace",
    field: "publicationPlace",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "publisher",
    field: "publisher",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "contributor",
    field: "contributor",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "rights",
    field: "rights",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "isbn",
    field: "isbn",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "dateManual",
    field: "dateManual",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "date",
    field: "date",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "format",
    field: "format",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "linkExterno",
    field: "linkExterno",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "bookType",
    field: "bookType",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "libroInterno",
    field: "libroInterno",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "royalties",
    field: "royalties",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "language",
    field: "language",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "language2",
    field: "language2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "language3",
    field: "language3",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "levelling",
    field: "levelling",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "identifier",
    field: "identifier",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag1",
    field: "tag1",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag2",
    field: "tag2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag3",
    field: "tag3",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag4",
    field: "tag4",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag5",
    field: "tag5",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag1 Eng",
    field: "tag1ENG",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag2 Eng",
    field: "tag2ENG",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag3 Eng",
    field: "tag3ENG",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag4 Eng",
    field: "tag4ENG",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag5 Eng",
    field: "tag5ENG",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "dba1",
    field: "dba1",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "dba2",
    field: "dba2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "dba3",
    field: "dba3",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "nivelUsuarioLector",
    field: "nivelUsuarioLector",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "interactivo",
    field: "interactivo",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tiempoLectura",
    field: "tiempoLectura",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "edad",
    field: "edad",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "curso",
    field: "curso",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "disponibilidadRegional",
    field: "disponibilidadRegional",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "valores",
    field: "valores",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "compatibilidad",
    field: "compatibilidad",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "genero",
    field: "genero",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "gratis",
    field: "gratis",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "sonido",
    field: "sonido",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "libroPremiado",
    field: "libroPremiado",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "libroAccesible",
    field: "libroAccesible",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "libroBilingue",
    field: "libroBilingue",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tipoDeLibro",
    field: "tipoDeLibro",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "portada",
    field: "portada",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "portadaThumb",
    field: "portadaThumb",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "screenshot1",
    field: "screenshot1",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "screenshot2",
    field: "screenshot2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "screenshot3",
    field: "screenshot3",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "recomendado1",
    field: "recomendado1",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "recomendado2",
    field: "recomendado2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "link1",
    field: "link1",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "textLink1",
    field: "textLink1",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "link2",
    field: "link2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "textLink2",
    field: "textLink2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "link3",
    field: "link3",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "textLink3",
    field: "textLink3",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "webUrl",
    field: "webUrl",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "linkBlog",
    field: "linkBlog",
    sort: "asc",
    minimal: "lg",
  },
];

export const columnasPaquetesInstitutosPL = [
  {
    label: "Paquete",
    field: "nombrePaquete",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Instituto",
    field: "superinstituto",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];

export const columnasPaquetesSedesPL = [
  {
    label: "Paquete",
    field: "nombrePaquete",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Instituto",
    field: "instituto",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Sede",
    field: "sede",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];

export const columnasLibrosPaquetes = [
  {
    label: "idLibro",
    field: "idLibro",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "title",
    field: "title",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Paquete",
    field: "paquete",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "subject",
    field: "subject",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "subject2",
    field: "subject2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "subject2ENG",
    field: "subject2ENG",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "description",
    field: "description",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "source",
    field: "source",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "relation",
    field: "relation",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "coverage",
    field: "coverage",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "author",
    field: "author",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "publisher",
    field: "publisher",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "contributor",
    field: "contributor",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "rights",
    field: "rights",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "isbn",
    field: "isbn",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "dateManual",
    field: "dateManual",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "date",
    field: "date",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "format",
    field: "format",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "linkExterno",
    field: "linkExterno",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "bookType",
    field: "bookType",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "libroInterno",
    field: "libroInterno",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "royalties",
    field: "royalties",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "language",
    field: "language",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "identifier",
    field: "identifier",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag1",
    field: "tag1",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag2",
    field: "tag2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag3",
    field: "tag3",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag4",
    field: "tag4",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag5",
    field: "tag5",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag1 Eng",
    field: "tag1ENG",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag2 Eng",
    field: "tag2ENG",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag3 Eng",
    field: "tag3ENG",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag4 Eng",
    field: "tag4ENG",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag5 Eng",
    field: "tag5ENG",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "dba1",
    field: "dba1",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "dba2",
    field: "dba2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "dba3",
    field: "dba3",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "nivelUsuarioLector",
    field: "nivelUsuarioLector",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "interactivo",
    field: "interactivo",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tiempoLectura",
    field: "tiempoLectura",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "edad",
    field: "edad",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "curso",
    field: "curso",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "disponibilidadRegional",
    field: "disponibilidadRegional",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "restriccionRegional",
    field: "restriccionRegional",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "numeroPalabras",
    field: "numeroPalabras",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "valores",
    field: "valores",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "compatibilidad",
    field: "compatibilidad",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "genero",
    field: "genero",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "gratis",
    field: "gratis",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "sonido",
    field: "sonido",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "libroPremiado",
    field: "libroPremiado",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "libroAccesible",
    field: "libroAccesible",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "libroBilingue",
    field: "libroBilingue",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tipoDeLibro",
    field: "tipoDeLibro",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "contenidoExtra1",
    field: "contenidoExtra1",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "contenidoExtra2",
    field: "contenidoExtra2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "portada",
    field: "portada",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "portadaThumb",
    field: "portadaThumb",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "screenshot1",
    field: "screenshot1",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "screenshot2",
    field: "screenshot2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "screenshot3",
    field: "screenshot3",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "recomendado1",
    field: "recomendado1",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "recomendado2",
    field: "recomendado2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "link1",
    field: "link1",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "textLink1",
    field: "textLink1",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "link2",
    field: "link2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "textLink2",
    field: "textLink2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "link3",
    field: "link3",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "textLink3",
    field: "textLink3",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "webUrl",
    field: "webUrl",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "linkBlog",
    field: "linkBlog",
    sort: "asc",
    minimal: "lg",
  },
];

export const columnasLibrosCursos = [
  {
    label: "idLibro",
    field: "idLibro",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "title",
    field: "title",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "subject",
    field: "subject",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "subject2",
    field: "subject2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "subject2ENG",
    field: "subject2ENG",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "description",
    field: "description",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "source",
    field: "source",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "relation",
    field: "relation",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "coverage",
    field: "coverage",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "author",
    field: "author",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "publisher",
    field: "publisher",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "contributor",
    field: "contributor",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "rights",
    field: "rights",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "isbn",
    field: "isbn",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "dateManual",
    field: "dateManual",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "date",
    field: "date",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "format",
    field: "format",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "linkExterno",
    field: "linkExterno",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "bookType",
    field: "bookType",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "libroInterno",
    field: "libroInterno",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "royalties",
    field: "royalties",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "language",
    field: "language",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "identifier",
    field: "identifier",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag1",
    field: "tag1",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag2",
    field: "tag2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag3",
    field: "tag3",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag4",
    field: "tag4",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag5",
    field: "tag5",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag1 Eng",
    field: "tag1ENG",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag2 Eng",
    field: "tag2ENG",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag3 Eng",
    field: "tag3ENG",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag4 Eng",
    field: "tag4ENG",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tag5 Eng",
    field: "tag5ENG",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "dba1",
    field: "dba1",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "dba2",
    field: "dba2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "dba3",
    field: "dba3",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "nivelUsuarioLector",
    field: "nivelUsuarioLector",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "interactivo",
    field: "interactivo",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "tiempoLectura",
    field: "tiempoLectura",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "edad",
    field: "edad",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "curso",
    field: "curso",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "disponibilidadRegional",
    field: "disponibilidadRegional",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "restriccionRegional",
    field: "restriccionRegional",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "numeroPalabras",
    field: "numeroPalabras",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "valores",
    field: "valores",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "compatibilidad",
    field: "compatibilidad",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "genero",
    field: "genero",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "gratis",
    field: "gratis",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "sonido",
    field: "sonido",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "contenidoExtra1",
    field: "contenidoExtra1",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "contenidoExtra2",
    field: "contenidoExtra2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "portada",
    field: "portada",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "portadaThumb",
    field: "portadaThumb",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "screenshot1",
    field: "screenshot1",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "screenshot2",
    field: "screenshot2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "screenshot3",
    field: "screenshot3",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "recomendado1",
    field: "recomendado1",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "recomendado2",
    field: "recomendado2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "link1",
    field: "link1",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "textLink1",
    field: "textLink1",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "link2",
    field: "link2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "textLink2",
    field: "textLink2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "link3",
    field: "link3",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "textLink3",
    field: "textLink3",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "webUrl",
    field: "webUrl",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "linkBlog",
    field: "linkBlog",
    sort: "asc",
    minimal: "lg",
  },
];

export const columnasClientes = [
  {
    label: "Username",
    field: "username",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Password",
    field: "passwd",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Email",
    field: "email",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Acceso Admin",
    field: "accessFull",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Acceso Cliente",
    field: "accessMid",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Acceso Subcliente",
    field: "accessLow",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Instituto",
    field: "institute",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Fecha Inicio Sub",
    field: "fechaIniSub",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Fecha Fin Sub",
    field: "fechaFinSub",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Identificador",
    field: "identificador",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Concurrencias",
    field: "concurrents",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Limite Lecturas Diarias",
    field: "limiteLecturasDiarias",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Código único",
    field: "uniqueCode",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Ip Inicio",
    field: "ipStart",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Ip Fin",
    field: "ipEnd",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Pais",
    field: "pais",
    sort: "asc",
    minimal: "lg",
  },
];
export const columnasLecturasLibros = [
  {
    label: "Instituto",
    field: "Instituto",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Usuario",
    field: "Usuario",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Identificacion",
    field: "Identificacion",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Dato",
    field: "Dato",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Dato2",
    field: "Dato2",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Fecha_Ingreso",
    field: "Fecha_Ingreso",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Tiempo_Sesion",
    field: "Tiempo_Sesion",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "idLibro",
    field: "idLibro",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Titulo",
    field: "Titulo",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Autor",
    field: "Autor",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Editorial",
    field: "Editorial",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Genero",
    field: "Genero",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Nivel",
    field: "Nivel",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Isbn",
    field: "Isbn",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Idioma",
    field: "Idioma",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Formato",
    field: "Formato",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Session",
    field: "Sesion",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "IP",
    field: "IP",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Acceso App",
    field: "appToken",
    sort: "asc",
    minimal: "lg",
  },
];
export const columnasAccesos = [
  {
    label: "Fecha de registro",
    field: "sessionDate",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Usuario",
    field: "username",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Duración de la sesión",
    field: "sessionDuration",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Instituto",
    field: "institute",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Identificador",
    field: "identificador",
    sort: "asc",
    minimal: "lg",
  },
];

export const columnasIdps = [
  {
    label: "ID SAML",
    field: "idInstitutoSAML",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Usuario de Login",
    field: "username",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "entityID MakeMake",
    field: "eIdMkmk",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label:
      'SSO para login manual MakeMake (despues de "redirect" se pone la ruta, en MakeMake, donde se quiere llegar)',
    field: "ssoMkmk",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "ACS MakeMake",
    field: "acsMkmk",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "SLS MakeMake",
    field: "slsMkmk",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "entityID del Cliente",
    field: "entityId",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "SSO del Cliente",
    field: "singleSignOnServiceUrl",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "SLS del Cliente",
    field: "singleLogoutServiceUrl",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Vendedor",
    field: "vendedor",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];

export const columnasActividades = [
  {
    label: "Id Actividad",
    field: "idActividad",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Título Actividad",
    field: "title",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Id Libro Relacionado",
    field: "idLibroRelacionado",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Título Libro",
    field: "bookTitle",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "materia",
    field: "subject2",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Autor",
    field: "author",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Formato",
    field: "format",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];

export const columnasCodigosRegalo = [
  {
    label: "Código asignado",
    field: "giftCode",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Instituto",
    field: "institute",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Duración del demo (en días, después de ser reclamado)",
    field: "durationDays",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Fecha de asignación",
    field: "tsCreation",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Fecha de reclamo",
    field: "tsClaim",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Paquete Incluido",
    field: "bookPackage",
    sort: "asc",
    minimal: "lg",
  },
];

export const columnasHabilitarPaquetesClientes = [
  {
    label: "Usuario del Cliente",
    field: "Cliente",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Paquete",
    field: "Paquete",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];

export const columnasAutorizarPaqueteCliente = [
  {
    label: "Id Libro",
    field: "idLibro",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Título",
    field: "title",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Autor",
    field: "author",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Paquete",
    field: "paquete",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Editorial",
    field: "publisher",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Tipo de Libro",
    field: "bookType",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];

export const columnasAgregarEliminarPaqueteCliente = [
  {
    label: "Usuario del cliente",
    field: "username",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Paquete",
    field: "paquete",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];

export const columnasSolicitudesDemo = [
  {
    label: "Nombre del solicitante",
    field: "nombre",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Correo del solicitante",
    field: "correo",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Edad del solicitante",
    field: "edad",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Teléfono del solicitante",
    field: "telefono",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Instituto",
    field: "instituto",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Interés en el demo",
    field: "interes",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Tipo de solicitud de demo",
    field: "tipoDemo",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Fecha de solicitud",
    field: "tsCheck",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Ciudad",
    field: "ciudad",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "País",
    field: "pais",
    sort: "asc",
    minimal: "lg",
  },
];
export const columnasColeccion = [
  {
    label: "Id Libro",
    field: "idLibro",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Titulo",
    field: "title",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Autor",
    field: "author",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Valores",
    field: "valores",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Genero",
    field: "genero",
    sort: "asc",
    minimal: "lg",
  },
];

export const columnasEstadisticasGlobalesAcceso = [
  {
    label: "Fecha",
    field: "date",
    sort: "",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Número de sesiones",
    field: "numSessions",
    sort: "",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Duración de las sesiones (en minutos)",
    field: "durationSessions",
    sort: "",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Duración promedio de las sesiones (en minutos)",
    field: "avgSessions",
    sort: "",
    minimal: "lg",
    isVisible: true,
  },
];

export const columnasEstadisticasGlobalesLectura = [
  {
    label: "Manuvo Id",
    field: "idLibro",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Título",
    field: "title",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Autor",
    field: "author",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "No. veces leído",
    field: "vecesLeido",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Tiempo de lectura promedio (en minutos)",
    field: "promedioTiempo",
    sort: "asc",
    isVisible: true,
    minimal: "lg",
  },
];

export const columnasListaCortesRegalias = [
  {
    label: "Detalle",
    field: "detalle",
    minimal: "lg",
  },
  {
    label: "Corte Regalias",
    field: "idCorte",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Usuario",
    field: "usernameClient",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Cliente",
    field: "nombreCliente",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Tipo de suscripción",
    field: "tipoSuscripcion",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Renovación",
    field: "renovacion",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Recompra dentro del periodo",
    field: "recompraDentroSuscripcion",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Nuevo cliente",
    field: "nuevoCliente",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Divisa",
    field: "moneda",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Número libros",
    field: "numeroLibros",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Nombre del paquete",
    field: "packageName",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "No. Usuarios",
    field: "numeroUsuarios",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Fecha inicio suscripción",
    field: "fechaIniSub",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Fecha fin suscripción",
    field: "fechaFinSub",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "# Factura",
    field: "numeroFactura",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Precio de venta",
    field: "precioTotalVenta",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Retefuente y/o ReteICA",
    field: "reteFuente",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Estampillas",
    field: "reteICA",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Licenciamiento software",
    field: "descuentosBancarios",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Subtotal",
    field: "subtotal",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Proporción de ocupación total",
    field: "proporcionDeOcupacionTotal",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Transmisión de datos",
    field: "costoTransmisionDatos",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Configuración colegios (SAML o Similar)",
    field: "costoConfiguracion",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Hardware cajitas",
    field: "hardwareCajitas",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Talleres",
    field: "talleres",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Valor neto",
    field: "valorNeto",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Precio ePub básico",
    field: "precioEpubBasico",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "No. ePub básicos",
    field: "numeroEpubBasico",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Precio ePub premium",
    field: "precioEpubPremium",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "No. ePub premium",
    field: "numeroEpubPremium",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Precio inter básico",
    field: "precioInterBasico",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "No. inter básicos",
    field: "numeroInterBasico",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Precio inter premium",
    field: "precioInterPremium",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "No. inter premium",
    field: "numeroInterPremium",
    sort: "asc",
    minimal: "lg",
  },
];

export const columnasCortePorEditorial = [
  {
    label: "Detalle",
    field: "detalle",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Editorial",
    field: "publisher",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Número Total de Libros",
    field: "numeroDeLibrosTotal",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Numero de ePub Básicos",
    field: "epubB",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Numero de ePub Premium",
    field: "epubP",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Numero de Interactivos Básicos",
    field: "interB",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Numero de Interactivos Premium",
    field: "interP",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Total de ePub Básicos",
    field: "epubBTotal",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Total de ePub Premium",
    field: "epubPTotal",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Total de Interactivos Básicos",
    field: "interBTotal",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Total de Interactivos Premium",
    field: "interPTotal",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Regalias Totales",
    field: "Total",
    sort: "asc",
    minimal: "lg",
  },
];

export const columnasLibrosPorEditorialRegalias = [
  {
    label: "Id Libro",
    field: "idLibro",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Título",
    field: "title",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Autor",
    field: "author",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Editorial",
    field: "publisher",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "ISBN",
    field: "isbn",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Número de ventas",
    field: "numeroDeVentas",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Valor antes del ajuste",
    field: "ValorRegalias",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Porcentaje",
    field: "royalties",
    sort: "asc",
    minimal: "lg",
  },
  {
    label: "Valor Regalías",
    field: "Total",
    sort: "asc",
    minimal: "lg",
  },
];

export const columnasTags = [
  {
    label: "Español",
    field: "tag",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Inglés",
    field: "tagEng",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
  {
    label: "Descripción",
    field: "description",
    sort: "asc",
    minimal: "lg",
    isVisible: true,
  },
];
