import React, { useState, useEffect } from "react";
// material ui components
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { DropzoneArea } from "material-ui-dropzone";
// ICONS
import { GENERAL_URL_API } from "../../../shared/urls";
import Skeleton from "@material-ui/lab/Skeleton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CloseRounded } from "@material-ui/icons";
import CheckRounded from "@material-ui/icons/CheckRounded";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";

import swal from "sweetalert";

const AgregarNuevaActividadForm = () => {
  const [book, setBook] = useState({
    value: null,
    error: false,
    required: true,
  });
  const [bookOptions, setBookOptions] = useState([]);
  const [formData, setFormData] = useState(fData);
  const [file, setFile] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    const fd = new FormData();
     
    const res = await fetch(`${GENERAL_URL_API}/verDatos/verLibros.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setBookOptions(data.data);
  };

  useEffect(() => fetchData(), []);

  const changeBook = (value) => {
    setBook({
      ...book,
      value,
    });
    console.log(book);
  };

  const handlePreviewIcon = (fileObject, classes) => {
    const { type } = fileObject.file;
    const iconProps = {
      className: classes.image,
      style: {
        opacity: "0.3",
        margin: "auto",
      },
    };
    if (type.startsWith("application/pdf"))
      return <PictureAsPdfOutlinedIcon {...iconProps} />;
  };

  /*maneja los cambios en el form actualizando el estado
  name es el name del element (e.target.name) ...similar para value
  si no hay condición debe enviarse codition = true
  si hay condición, condition debe realizar las evaluaciones correspondientes y retornar true|false */
  const handleChange = (
    e,
    name = e.target.name,
    value = e.target.value,
    condition = true
  ) => {
    if (condition === true || condition(value)) {
      setFormData({
        ...formData,
        [name]: {
          ...formData[name],
          value,
        },
      });
    }
  };

  const clearForm = () => {
    changeBook(null);
    setFormData(fData);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = { ...formData };
    let isValid = true;

    //si alguno de los campos requeridos está vacío
    for (const formElement in data) {
      if (data[formElement].required && !data[formElement].value) {
        data[formElement].error = true;
        isValid = false;
      } else {
        data[formElement].error = false;
      }
    }
    if (!book.value) {
      book.error = true;
      isValid = false;
    }
    //si no hay archivo
    if (!file || file.length === 0) {
      isValid = false;
      document.querySelector(".MuiDropzoneArea-root").classList.add("error");
    }
    //Actualizamos el estado con todos los cambios
    setFormData(data);
    //Si no hay errores limpia el formulario y maneja el submit
    if (isValid) {
      document.querySelector(".MuiDropzoneArea-root").classList.remove("error");
      //handle data here
      const data = {};
      data.book = book;
      data.file = file;
      Object.keys(formData).forEach((key) => {
        data[key] = formData[key].value;
      });
      const fd = new FormData();
       
       
      fd.append("idLibroRelacionado", book.value.idLibro);
      fd.append("title", formData.title.value);
      fd.append("author", formData.author.value);
      fd.append("subject2", formData.subject.value);
      fd.append("description", formData.description.value);

      fd.append("actividad", file[0]);

      try {
        const res = await fetch(
          `${GENERAL_URL_API}/actividades/crearActividad.php`,
          {
            method: "POST",
            credentials: "include",
            body: fd,
          }
        );
        const data = await res.json();
        if (data.status === 1) {
          await swal({
            title: "Actividad creada.",
            text: "La actividad se creo satisfactoriamente!",
            icon: "success",
          });
          clearForm();
        } else {
          await swal({
            title: "Sorry.",
            text: "No se pudo crear la actividad, comunicate con soporte.",
            icon: "error",
          });
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
  };

  // solo funciona para textfields, si hay autocomplete components no
  const textFields = Object.keys(formData).map((key, index) => {
    const formElement = formData[key];
    let onChangeCondition = true;
    if (formElement.condition) {
      onChangeCondition = formElement.condition;
    }
    return (
      <React.Fragment key={index}>
        <label className="admin-label-text mt-3 d-block">
          {formElement.label}
        </label>
        <TextField
          fullWidth
          multiline={formElement.multiline ? formElement.multiline : false}
          minRows={formElement.multiline ? 4 : 1}
          maxrows={formElement.multiline ? 4 : 1}
          variant="outlined"
          size="small"
          type="text"
          value={formElement.value}
          name={key}
          placeholder={formElement.placeholder && formElement.placeholder}
          onChange={(e) => {
            const name = e.target.name;
            let value = e.target.value;
            if (formElement.uppercase) value = e.target.value.toUpperCase();
            handleChange(e, name, value, onChangeCondition);
          }}
          error={formElement.error ? formElement.error : false}
          helperText={formElement.error ? "Campo no válido" : null}
        />
      </React.Fragment>
    );
  });

  return !bookOptions.length ? (
    <>
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
    </>
  ) : (
    <form onSubmit={handleSubmit}>
      <label className="admin-label-text mt-3 d-block">
        Libro Relacionado*
      </label>
      <Autocomplete
        options={bookOptions}
        getOptionLabel={(option) => option.title}
        value={book.value}
        onChange={(_, value) => changeBook(value)}
        noOptionsText="Sin resultados"
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              variant="outlined"
              size="small"
              fullWidth
              style={{
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
              error={book.error}
              helperText={book.error ? "Campo no válido" : null}
            />
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              value={book.value ? book.value.idLibro : ""} //debe mostrar el manuvoId
              disabled
            />
          </>
        )}
      />

      {textFields}

      <div className="mt-4 w-100">
        <label className="admin-label-text mt-3 d-block">
          Archivo pdf* <br />
          <span className="admin-muted-text">
            1 archivo requerido con extensión pdf ["content.pdf"]
          </span>
        </label>
        <DropzoneArea
          id="dropzone"
          dropzoneText="Arrastra un archivo o haz click aquí!"
          showFileNames={true}
          maxFileSize={30000000} //n in bytes
          showAlerts={true}
          filesLimit={1}
          acceptedFiles={["application/pdf"]}
          getPreviewIcon={handlePreviewIcon}
          onChange={(file) => {
            setFile(file);
          }}
        />
      </div>
      <div className="mt-3 w-100 d-flex justify-content-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckRounded />}
          style={{ background: "#81c868" }}
          onClick={handleSubmit}
          disabled={loading}
        >
          Agregar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="ml-2"
          startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
          style={{ background: "#f05050" }}
          onClick={clearForm}
        >
          Cancelar
        </Button>
      </div>
    </form>
  );
};

export default AgregarNuevaActividadForm;

const fData = {
  title: {
    label: "Titulo",
    value: "",
    error: false,
    required: false,
  },
  author: {
    label: "Autor",
    value: "",
    error: false,
    required: false,
  },
  subject: {
    label: "Materia",
    value: "",
    error: false,
    required: false,
  },
  description: {
    label: "Description",
    placeholder: "Description",
    multiline: true,
    value: "",
    error: false,
    required: false,
  },
};
