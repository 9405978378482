import React, { useState, useEffect } from "react";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomGreenButton from "../../../../components/shared/custom-green-button";
import { DropzoneArea } from "material-ui-dropzone";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import { GENERAL_URL_API } from "../../../../shared/urls";
import swal from "sweetalert";

const EditarActividadForm = (props) => {
  //Definimos los estados para almacenar los datos del formulario y sus errores
  //La informacion por defecto es la del blog seleccionado
  const [loading, setLoading] = useState(false);
  const [book, setBook] = useState({
    value: { idLibro: props.selectedOption.idLibroRelacionado },
    error: false,
    required: true,
  });
  const [bookOptions, setBookOptions] = useState([]);
  const [formData, setFormData] = useState({
    title: {
      label: "Titulo",
      value: props.selectedOption.title,
      error: false,
      required: false,
    },
    author: {
      label: "Autor",
      value: props.selectedOption.author,
      error: false,
      required: false,
    },
    subject: {
      label: "Materia",
      value: props.selectedOption.subject2,
      error: false,
      required: false,
    },
    description: {
      label: "Description",
      placeholder: "Description",
      multiline: true,
      value: props.selectedOption.description,
      error: false,
      required: false,
    },
  });
  const [file, setFile] = useState(undefined);

  const fetchData = async () => {
    const fd = new FormData();
     
    const res = await fetch(`${GENERAL_URL_API}/verDatos/verLibros.php`, {
      method: "POST", credentials: 'include',
      body: fd,
    });
    const data = await res.json();
    setBookOptions(data.data);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeBook = (value) => {
    setBook({
      ...book,
      value,
    });
    console.log(book);
  };

  const clearForm = () => {
    changeBook(null);
    setFormData(fData);
  };

  const handleChange = (
    e,
    name = e.target.name,
    value = e.target.value,
    condition = true
  ) => {
    if (condition === true || condition(value)) {
      setFormData({
        ...formData,
        [name]: {
          ...formData[name],
          value,
        },
      });
    }
  };

  const handlePreviewIcon = (fileObject, classes) => {
    const { type } = fileObject.file;
    const iconProps = {
      className: classes.image,
      style: {
        opacity: "0.3",
        margin: "auto",
      },
    };
    if (type.startsWith("application/pdf"))
      return <PictureAsPdfOutlinedIcon {...iconProps} />;
  };

  // solo funciona para textfields, si hay autocomplete components no
  const textFields = Object.keys(formData).map((key, index) => {
    const formElement = formData[key];
    let onChangeCondition = true;
    if (formElement.condition) {
      onChangeCondition = formElement.condition;
    }
    return (
      <React.Fragment key={index}>
        <label className="admin-label-text mt-3 d-block">
          {formElement.label}
        </label>
        <TextField
          fullWidth
          multiline={formElement.multiline ? formElement.multiline : false}
          minRows={formElement.multiline ? 4 : 1}
          maxrows={formElement.multiline ? 4 : 1}
          variant="outlined"
          size="small"
          type="text"
          value={formElement.value}
          name={key}
          placeholder={formElement.placeholder && formElement.placeholder}
          onChange={(e) => {
            const name = e.target.name;
            let value = e.target.value;
            if (formElement.uppercase) value = e.target.value.toUpperCase();
            handleChange(e, name, value, onChangeCondition);
          }}
          error={formElement.error ? formElement.error : false}
          helperText={formElement.error ? "Campo no válido" : null}
        />
      </React.Fragment>
    );
  });

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const data = { ...formData };
    let isValid = true;

    //si alguno de los campos requeridos está vacío
    for (const formElement in data) {
      if (data[formElement].required && !data[formElement].value) {
        data[formElement].error = true;
        isValid = false;
      } else {
        data[formElement].error = false;
      }
    }
    if (!book.value) {
      book.error = true;
      isValid = false;
    }

    //Actualizamos el estado con todos los cambios
    setFormData(data);
    //Si no hay errores limpia el formulario y maneja el submit
    if (isValid) {
      clearForm();
      document.querySelector(".MuiDropzoneArea-root").classList.remove("error");
      //handle data here
      const data = {};
      data.book = book;
      data.file = file;
      Object.keys(formData).forEach((key) => {
        data[key] = formData[key].value;
      });
      const fd = new FormData();
       
       
      fd.append("idActividad", props.selectedOption.idActividad);
      fd.append("idLibroRelacionado", book.value.idLibro);
      fd.append("title", formData.title.value);
      fd.append("author", formData.author.value);
      fd.append("subject2", formData.subject.value);
      fd.append("description", formData.description.value);

      if (file !== undefined) {
        fd.append("actividad", file[0]);
      }

      try {
        const res = await fetch(
          `${GENERAL_URL_API}/actividades/editarActividad.php`,
          {
            method: "POST",
            credentials: "include",
            body: fd,
          }
        );
        const data = await res.json();
        if (data.status === 1) {
          await swal({
            title: "Actividad editada.",
            text: "La actividad se edito satisfactoriamente!",
            icon: "success",
          });
        } else {
          await swal({
            title: "Sorry.",
            text: "No se pudo editar la actividad, comunicate con soporte.",
            icon: "error",
          });
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <div className="admin-muted-text-small mt-3">
        Por favor modifique la información que desee cambiar (Si el usuario no
        existe, debe crearse primero):
      </div>
      <CustomGreenButton
        className="mt-3"
        size="small"
        onClick={props.clearSelection}
      >
        Regresar
      </CustomGreenButton>
      <form>
        <label className="admin-label-text mt-3 d-block">
          Libro Relacionado*
        </label>
        <Autocomplete
          options={bookOptions}
          getOptionLabel={(option) => option.title}
          value={book.value}
          onChange={(_, value) => changeBook(value)}
          noOptionsText="Sin resultados"
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                variant="outlined"
                size="small"
                fullWidth
                style={{
                  fontFamily: "'Noto Sans JP', sans-serif",
                }}
                error={book.error}
                helperText={book.error ? "Campo no válido" : null}
              />
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="text"
                value={book.value ? book.value.idLibro : ""} //debe mostrar el manuvoId
                disabled
              />
            </>
          )}
        />

        {textFields}

        <div className="mt-4 w-100">
          <label className="admin-label-text mt-3 d-block">
            Archivo pdf* <br />
            <span className="admin-muted-text">
              1 archivo requerido con extensión pdf
            </span>
          </label>
          <DropzoneArea
            id="dropzone"
            dropzoneText="Arrastra un archivo o haz click aquí!"
            showFileNames={true}
            maxFileSize={30000000} //n in bytes
            showAlerts={true}
            filesLimit={1}
            acceptedFiles={["application/pdf"]}
            getPreviewIcon={handlePreviewIcon}
            onChange={(file) => {
              setFile(file);
            }}
          />
        </div>
        <div className="d-flex justify-content-end mt-4">
          <CustomGreenButton
            variant="contained"
            className="mr-2"
            style={{ background: "#5d9cec" }}
            onClick={handleSubmit}
            disabled={loading}
          >
            Finalizar
          </CustomGreenButton>
          <CustomGreenButton variant="contained">Cancelar</CustomGreenButton>
        </div>
      </form>
    </>
  );
};

export default EditarActividadForm;

const fData = {
  title: {
    label: "Titulo",
    value: "",
    error: false,
    required: false,
  },
  author: {
    label: "Autor",
    value: "",
    error: false,
    required: false,
  },
  subject: {
    label: "Materia",
    value: "",
    error: false,
    required: false,
  },
  description: {
    label: "Description",
    placeholder: "Description",
    multiline: true,
    value: "",
    error: false,
    required: false,
  },
};
